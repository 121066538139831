var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"ma-0",attrs:{"maxHeight":_vm.height,"minHeight":_vm.minHeight}},[_c('v-card-title',{staticClass:"error white--text py-0"},[_vm._v(" Просроченные задачи ("+_vm._s(_vm.data.length)+") ")]),_c('v-divider'),_c('v-card-text',{staticClass:"mt-0 pa-1 pt-0"},[(_vm.data.length > 0)?_c('v-simple-table',{ref:"tableLate",attrs:{"fixed-header":"","dense":"","height":_vm.height - 36},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{staticClass:"main-table-header"},[_c('tr',[_c('th',{staticClass:"text-left px-1"}),_c('th',{staticClass:"text-left px-1"},[_vm._v("Дэдлайн")]),_c('th',{staticClass:"text-left px-1"},[_vm._v("Заголовок")]),_c('th',{staticClass:"text-left px-1"},[_vm._v("Стасус")])])]),_c('tbody',_vm._l((_vm.data.slice(0, _vm.itemsPerPage)),function(el,i){return _c('tr',{key:i,staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$emit('click', el)}}},[_c('td',{staticClass:"text-left px-1"},[_c('a-view-table-chip',{attrs:{"value":el.is_important,"model":{
                  hideText: true,
                  showText: el.id,
                  options: _vm.$store.getters['config/get'].models.tasks.optionsIsImportant,
                }}})],1),_c('td',{staticClass:"text-left px-1"},[_c('a-view-table-date',{attrs:{"value":el.date_end}})],1),_c('td',{staticClass:"text-left px-1"},[_c('a-view-table-taskname',{attrs:{"value":el.name,"values":el}})],1),_c('td',{staticClass:"text-left px-1"},[_c('a-view-table-select',{attrs:{"value":el.status,"model":{
                  options: _vm.$store.getters['config/get'].models.tasks.statuses,
                }}})],1)])}),0)]},proxy:true}],null,false,713999437)}):_c('div',[_vm._v("Список пуст")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }